import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
   

   <path d="M1330 2365 c-172 -36 -311 -108 -439 -229 -363 -347 -360 -935 8
-1279 344 -322 886 -312 1221 23 499 499 237 1350 -458 1486 -90 17 -244 17
-332 -1z m410 -57 c238 -77 408 -221 510 -433 63 -129 79 -194 87 -335 9 -172
-27 -321 -112 -466 l-27 -45 -37 40 c-43 47 -56 50 -88 19 l-23 -21 -24 22
c-32 30 -80 30 -110 2 l-24 -23 -31 23 -31 23 -30 -23 -31 -22 -26 20 c-83 66
-187 -30 -122 -113 23 -29 69 -35 112 -15 19 9 31 7 57 -6 37 -19 66 -15 98
15 20 19 24 20 44 5 29 -19 47 -19 70 2 17 15 20 15 41 -4 12 -11 32 -24 45
-28 12 -3 22 -10 22 -14 0 -12 -89 -92 -143 -130 -61 -42 -173 -92 -257 -117
-101 -29 -328 -27 -435 5 -131 38 -257 109 -348 194 l-49 46 44 21 c41 20 45
20 79 4 33 -14 39 -14 67 0 27 14 34 14 59 1 35 -18 41 -18 75 -1 23 12 32 12
68 0 36 -12 44 -12 71 1 20 11 35 13 46 6 12 -6 28 -4 53 9 65 33 81 73 48
115 -19 24 -26 26 -67 21 -58 -7 -58 -7 -107 0 -25 3 -51 1 -64 -6 -16 -9 -25
-9 -33 -1 -15 15 -90 14 -105 -1 -9 -9 -15 -9 -24 0 -20 20 -79 14 -104 -9
l-23 -21 -29 21 c-17 11 -35 21 -41 21 -5 0 -27 -20 -47 -45 l-38 -44 -31 52
c-79 131 -115 264 -115 428 0 143 24 249 86 372 110 222 301 378 541 443 78
22 104 24 233 20 119 -3 159 -8 220 -28z m-813 -1272 c12 -24 19 -47 16 -50
-12 -11 -93 -6 -93 6 0 21 34 88 45 88 6 0 21 -20 32 -44z m153 29 c0 -10 -11
-16 -32 -16 -32 -2 -32 -2 -5 -6 58 -9 41 -63 -19 -63 -46 0 -48 29 -1 31 31
2 31 2 4 6 -58 9 -41 63 19 63 24 0 34 -5 34 -15z m130 0 c0 -10 -11 -16 -32
-16 -31 -2 -31 -2 -5 -6 15 -2 27 -8 27 -13 0 -5 -12 -11 -27 -13 -26 -4 -26
-4 5 -6 22 -1 32 -6 32 -17 0 -12 -9 -14 -42 -12 -41 3 -43 5 -46 36 -5 52 3
62 48 62 29 0 40 -4 40 -15z m133 0 c-1 -5 -17 -13 -35 -16 l-33 -6 33 -2 c17
0 32 -5 32 -11 0 -5 -15 -11 -32 -12 l-33 -1 33 -4 c49 -6 42 -33 -8 -33 l-40
0 0 51 0 50 43 -3 c23 -2 41 -7 40 -13z m131 0 c0 -5 -13 -13 -30 -16 l-29 -7
33 -1 c22 -1 32 -5 29 -13 -2 -7 -16 -12 -31 -10 -20 2 -26 -2 -26 -18 0 -11
-7 -20 -15 -20 -11 0 -15 12 -15 51 l0 50 43 -3 c23 -2 42 -7 41 -13z m242 7
c42 -28 14 -92 -41 -92 l-35 0 0 50 c0 50 0 50 33 50 17 0 37 -4 43 -8z m144
-32 c11 -23 18 -45 15 -50 -8 -13 -95 -13 -95 0 0 18 40 90 50 90 5 0 19 -18
30 -40z m150 25 c0 -8 -7 -15 -15 -15 -11 0 -15 -11 -15 -36 0 -44 -23 -42
-28 2 -2 16 -10 33 -18 37 -24 14 -5 27 37 27 28 0 39 -4 39 -15z m128 -27
c11 -24 17 -46 15 -50 -6 -12 -93 -9 -93 2 0 18 40 90 50 90 5 0 18 -19 28
-42z"/>
<path d="M1255 2106 c-151 -86 -226 -133 -238 -153 -16 -25 -17 -52 -15 -273
3 -279 5 -284 83 -320 75 -34 86 -26 100 76 8 55 41 128 76 168 32 37 87 68
95 54 4 -6 6 -97 6 -202 -1 -105 2 -204 6 -221 5 -16 25 -42 45 -58 64 -52
153 -47 222 13 11 9 36 23 55 31 19 7 66 32 105 55 38 23 99 57 135 76 94 49
94 49 98 329 5 287 7 278 -110 345 -300 170 -366 204 -404 204 -31 0 -84 -25
-259 -124z m255 -431 c0 -289 -3 -525 -7 -524 -5 0 -28 13 -53 28 l-45 27 -5
407 -5 407 -35 -23 c-19 -13 -74 -45 -122 -71 l-88 -48 0 -109 0 -110 28 19
c36 27 133 54 174 50 32 -3 31 -4 -32 -35 -36 -18 -61 -32 -56 -33 6 0 -4 -15
-21 -32 -45 -46 -84 -137 -91 -213 l-7 -64 -52 30 -53 31 0 266 1 267 227 127
c125 70 230 127 235 128 4 0 7 -236 7 -525z m255 395 l224 -125 1 -267 0 -266
-157 -89 c-87 -48 -161 -89 -165 -91 -4 -2 -8 25 -8 60 l0 63 110 60 110 59 0
202 0 202 -87 48 c-142 76 -191 114 -105 79 l37 -15 -25 20 c-14 11 -30 20
-37 20 -6 0 -17 2 -25 3 -7 2 -11 2 -8 0 3 -1 4 -187 2 -413 l-2 -410 -41 -26
c-22 -14 -46 -27 -52 -30 -9 -3 -13 124 -15 522 -3 387 -1 525 8 522 6 -2 112
-59 235 -128z m-404 -172 c1 -35 2 -72 3 -83 2 -36 -13 -55 -45 -55 -17 0 -51
-5 -76 -11 -55 -14 -65 -4 -59 61 3 25 5 48 5 52 1 8 151 98 163 98 5 0 8 -28
9 -62z m459 -17 c24 -19 24 -23 28 -178 2 -91 0 -169 -6 -184 -11 -27 -147
-113 -163 -103 -10 6 -13 527 -3 537 7 7 103 -41 144 -72z"/>
<path d="M1212 1944 c-35 -19 -57 -34 -49 -34 8 0 41 16 73 35 74 45 58 44
-24 -1z"/>
<path d="M1731 1986 c18 -20 124 -76 143 -75 20 1 20 2 1 6 -11 3 -49 21 -85
41 -36 19 -62 32 -59 28z"/>
<path d="M1886 1385 c-67 -35 -175 -99 -173 -102 4 -3 194 100 204 111 10 10
0 7 -31 -9z"/>
<path d="M1670 1030 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18
-13 2 -18 -3 -18 -18z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
